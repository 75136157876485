<template>
    <el-main>
        <el-tabs v-model="form.type" type="card" @tab-click="getList">
            <el-tab-pane label="就诊需求" :name="1"></el-tab-pane>
            <el-tab-pane label="既往史" :name="2"></el-tab-pane>
            <el-tab-pane label="过敏史" :name="3"></el-tab-pane>
            <el-tab-pane label="医生诊断" :name="4"></el-tab-pane>
            <el-tab-pane label="诊断图片" :name="5"></el-tab-pane>
        </el-tabs>
        <el-button style="margin-bottom: 10px" @click="addDiagnosisSet" size="small" type="primary" icon="el-icon-plus">添加内容</el-button>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="模板名称：">
                <el-input size="small" placeholder="请输入模板名称" v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="45px">
                <el-button type="primary" size="small" @click="getList">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="title" label="诊断内容" align="center">
            </el-table-column>
            <el-table-column prop="allergy_type" label="过敏类别" align="center" v-if="form.type==3">
                <template v-slot="{ row }">{{row.allergy_type==1?'药物':'食物'}}</template>
            </el-table-column>
            <el-table-column prop="title" label="诊断详细内容" align="center" v-if="form.type==4">
            </el-table-column>
            <el-table-column prop="operator_name" label="创建人" align="center">
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center">
                <template v-slot="{ row }">{{row.create_time|getDateformat}}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="editDiagnosisSet(row)">编辑</el-button>
                    <el-button type="text" @click="delDiagnosisSet(row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>

        <!-- 内容表单 -->
        <el-dialog :title="type=='add'?'添加内容':'编辑内容'" :visible.sync="dialogVisible" width="50%">
            <el-form label-width="120px" label-position="left" ref="set_form" :model="set_form" :rules="rules">
                <el-form-item label="过敏类别：" prop="allergy_type"  v-if="set_form.type==3">
                     <el-radio-group v-model="set_form.allergy_type">
                        <el-radio :label="1">药物</el-radio>
                        <el-radio :label="2">食物</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="诊断内容：" prop="title">
                    <el-input size="small" placeholder="请输入诊断内容" v-model="set_form.title"></el-input>
                </el-form-item>
                <el-form-item label="诊断详细内容：" v-if="set_form.type==4" prop="content">
                    <el-input size="small" placeholder="请输入诊断详细内容" v-model="set_form.content" type="textarea" :rows="8"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmDiagnosisSet">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components:{
        Paging
    },
    data(){
        return {
            form:{
                page:1,
                rows:10,
                type:1,
                title:'',
            },
            list:[],
            total:0,

            type:'add',
            dialogVisible:false,
            set_form:{
                title:'',
                content:'',
                type:'',
                allergy_type:1
            },
            rules:{
                title:[{ required: true, message: '请输入诊断内容', trigger: 'blur' }],
                content:[{ required: true, message: '请输入诊断详细内容', trigger: 'blur' }],
                allergy_type:[{ required: true, message: '请选择过敏类别', trigger: 'change' }]
            }
    };
  },
  created() {
    this.getList();
  },
  filters: {
    getDateformat: getDateformat,
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getList();
      } else {
        this.form.page = val;
        this.getList();
      }
    },
    handleTab() {
      this.getList();
    },
    getList() {
        this.$axios.post(this.$api.beauty.diagnosisSetList, this.form).then(res => {
            if (res.code == 0) {
                this.total = res.result.total;
                this.list = res.result.list;
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    editDiagnosisSet(row) {
        this.type = 'edit';
        this.set_form = {
            id: row.id,
            title: row.title,
            content: row.content,
            allergy_type:row.allergy_type
        };
        this.set_form.type = this.form.type;
        this.dialogVisible = true;
    },
    addDiagnosisSet() {
        this.type = 'add';
        this.set_form = {
            title: '',
            content: '',
            allergy_type:1
        };
        this.set_form.type = this.form.type;
        this.dialogVisible = true;
    },
    confirmDiagnosisSet() {
        this.$refs.set_form.validate(valid => {
            if (valid) {
                let url = this.type == 'add' ? this.$api.beauty.diagnosisSetAdd : this.$api.beauty.diagnosisSetEdit;
                this.$axios.post(url, this.set_form).then(res => {
                    if (res.code == 0) {
                    let msg = this.type == 'add' ? '添加成功' : '编辑成功';
                    this.$message.success(msg);
                    this.dialogVisible = false;
                    this.getList();
                    } else {
                    this.$message.error(res.msg);
                    }
                })
            }
        })
    },
    delDiagnosisSet(id) {
      this.$confirm('确定要删除该内容吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.post(this.$api.beauty.diagnosisSetDel, { id }).then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
